import { arrayOf, func, number, string, shape } from 'prop-types';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link as RouterLink } from 'react-router-dom';
import { copyText } from 'language';
/**
 *
 * @param {
 * columns,
 * idColumn,
 * rowData,
 * numRows,
 * handleListItemClick,
 * handlePageChange,
 *  currentPage
 * }props
 *  columns - should be an array of strings with the table headers in the order that you want them to appear.
 *  idColumn - idColumn is a string with the header for the identifying field. This will be the first header on the top of the table.
 *  rowData - rowData should be an array of objects containing the id:This should be the identifying field for the row. and columns: This should be an array of the rest of the fields to be displayed in the remaining columns in the order that you want to display them.
 * numRows - This should be the number value of the number of rows to be displayed on the table.
 * handleListItemClick - This should be a function that performs any actions when a row id is clicked on.
 * handlePageChange - This should be a function that performs any functionality when the page is changed. (e.g. On the orders page this function is used to call the Mpos Api and retrieve the next page of orders)
 * currentPage - This should be the number value of the current page of the table. (This is 0 based.)
 * @returns
 */
const TableLayout = (props) => {
  const {
    columns,
    idColumn,
    rowData,
    numRows,
    handleListItemClick,
    handlePageChange,
    currentPage,
    setCurrentPage,
    setLoading,
    filters,
    setFilters,
    setFoundOrderData,
  } = props;

  return (
    <>
      <TableContainer component={Paper} sx={{ height: { xs: 650, md: 400 }, pl: 1, pr: 1 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow sx={{ borderBottom: 3 }}>
              <TableCell sx={{ fontWeight: 'bold' }}>{idColumn}</TableCell>
              {columns.map((col, ind) => {
                const key = col.title || ind;
                return (
                  <TableCell align="right" key={key}>
                    <Tooltip key={col.title} title={col.tooltip || ''} placement="top" arrow>
                      <Typography sx={{ fontWeight: 'bold' }} component="span" variant="none">
                        {col.title}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData.length < 1 && (
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ fontWeight: 'bold' }}>
                  {copyText.Layouts.TableLayout.noDataFound}
                </TableCell>
              </TableRow>
            )}
            {rowData.map((row) => (
              <TableRow key={row?.id} value={row} sx={{ borderBottom: 2 }}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ display: 'flex', alignContent: 'center' }}
                >
                  <Link
                    fontWeight="bold"
                    component={RouterLink}
                    onClick={() => handleListItemClick(row)}
                    to={row?.link}
                  >
                    {row?.id}
                  </Link>
                  <ArrowForwardIosIcon fontSize="small" sx={{ ml: 1 }} />
                </TableCell>
                {row?.columns.map((col) => (
                  <TableCell key={col} align="right">
                    {col}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {rowData.length > 0 && (
        <TablePagination
          page={currentPage}
          component="div"
          count={numRows}
          rowsPerPage={20}
          rowsPerPageOptions={[20]}
          onPageChange={(event, newPage) =>
            handlePageChange({
              page: newPage,
              filters,
              setLoading,
              setFoundOrderData,
              setFilters,
              setCurrentPage,
            })
          }
        />
      )}
    </>
  );
};

export default TableLayout;

TableLayout.propTypes = {
  columns: arrayOf(shape({})),
  idColumn: string.isRequired,
  rowData: arrayOf(shape({})),
  numRows: number.isRequired,
  handleListItemClick: func,
  handlePageChange: func.isRequired,
  currentPage: number,
  setCurrentPage: func.isRequired,
  setLoading: func.isRequired,
  filters: shape({ orderType: arrayOf(string), storeKey: string, customerEmail: string }),
  setFilters: func.isRequired,
  setFoundOrderData: func.isRequired,
};

TableLayout.defaultProps = {
  columns: [],
  rowData: [],
  handleListItemClick: () => {},
  currentPage: 0,
  filters: {},
};

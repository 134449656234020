import React from 'react';
import { Route, useHistory, Switch, Redirect } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { string, shape } from 'prop-types';
import { useIdleTimer } from 'react-idle-timer';
import { StoreContextProvider } from 'contexts';

// --- Global ---
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import oktaConfig from 'utils/oktaConfig';
import { time } from 'utils/time';
import theme from 'theme';
import cloverUtils from 'utils/clover';

// --- Views ---
import Page404 from 'views/Page404/Page404';
import CartHistory from 'views/CartHistory/CartHistory';
import CloverAccounts from 'views/CloverAccounts/CloverAccounts';
import RetailShop from 'views/Shop/RetailShop';
import OutletShop from 'views/Shop/OutletShop';
import Login from 'views/Login/Login';
import Orders from 'views/Orders';
import Cart from 'views/Cart/Cart';
import ErrorLogs from 'views/ErrorLogs/ErrorLogs';
import Troubleshooting from 'views/Troubleshooting/Troubleshooting';

// --- Public Pages ---
import Signage from 'public/Signage/Signage';
// SUNRISE: Add Interactive Canopy Experience page.

// --- Okta ---
const oktaAuth = new OktaAuth(oktaConfig);

// start the instance of oktaAuth so as to enable token auto renewal
await oktaAuth.start();

const disallowedPaths = ['/', '/pin', '/signage'];
const showPageTheme = (pathname) => {
  let showPage = true;
  const pathFound = !!disallowedPaths.find((path) => path === pathname);
  showPage = !pathFound;

  return showPage;
};

// --- Header ---
const HeaderContainer = ({ location }) => {
  const { pathname } = location;
  return showPageTheme(pathname) && <Header />;
};

HeaderContainer.propTypes = {
  location: shape({
    pathname: string.isRequired,
  }).isRequired,
};

// --- Footer ---
const FooterContainer = ({ location }) => {
  const { pathname } = location;
  return showPageTheme(pathname) && <Footer />;
};

FooterContainer.propTypes = {
  location: shape({
    pathname: string.isRequired,
  }).isRequired,
};

// --- App ---
const App = () => {
  const timeout = time.hours(5);
  const onIdle = () => {
    alert('timed out');
    cloverUtils.clearCloverStorage();
    oktaAuth.signOut();
  };

  useIdleTimer({ onIdle, timeout });

  const history = useHistory();
  const restoreOriginalUri = async () => {
    history.replace(toRelativeUrl('/shop/retail', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <StoreContextProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <HeaderContainer location={window.location} />

          <article role="main">
            <Switch>
              {/* --- Public Pages --- */}
              <Route path="/signage" component={Signage} />

              {/* --- START Routes --- */}
              <Route exact path="/" component={Login} />
              <Route exact path="/shop">
                <Redirect to="/shop/retail" />
              </Route>
              <SecureRoute path="/shop/retail" component={RetailShop} />
              <SecureRoute path="/shop/outlet" component={OutletShop} />
              <SecureRoute path="/orders" component={Orders} />
              <SecureRoute path="/cart" component={Cart} />
              <SecureRoute path="/cart-history" component={CartHistory} />
              <SecureRoute path="/employees" component={CloverAccounts} />
              <Route exact path="/login-okta" component={LoginCallback} />
              <SecureRoute path="/error-logs" component={ErrorLogs} />
              <SecureRoute path="/troubleshooting" component={Troubleshooting} />
              {/* --- END Routes --- */}

              {/* 404 Page */}
              <SecureRoute path="*" component={Page404} />
            </Switch>
            <FooterContainer location={window.location} />
          </article>
        </ThemeProvider>
      </StoreContextProvider>
    </Security>
  );
};

export default App;
